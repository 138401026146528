export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
        try {
            resolve(e.target.result);
        }catch(e){
            reject(e);
        }

 
     };
     reader.readAsDataURL(file);
  });
}
