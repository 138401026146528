<template>
  <div :class="{'with-freestyle': includeFreeText, 'without-freestyle': !includeFreeText}">
    <b-card>
      <b-tabs v-model="tabIndex" class="input-switch">
        <b-tab title="Brief">
          <b-card-title>{{ title }}</b-card-title>

          <b-form v-if="sampleinputs && sampleinputs.length > 0">
            <b-col
              cols="12"
              :key="index"
              v-for="(input, index) in sampleinputs"
            >
              <div v-if="!input.hidden">
                <contilt-input
                  v-if="input.type == inputTypes.INPUT"
                  :input="input"
                  v-on:input:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-input>
                <contilt-image
                  v-if="input.type == inputTypes.IMAGE"
                  :input="input"
                  v-on:image:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-image>
                <contilt-csv-file
                  v-if="input.type == inputTypes.CSV_FILE"
                  :input="input"
                  v-on:file:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-csv-file>
                <for-input
                  v-if="input.type == inputTypes.FOR_INPUT"
                  :input="input"
                  v-on:forInput:change="onInputchanged(input, $event)"
                >
                </for-input>
                <contilt-json-file
                  v-if="input.type == inputTypes.JSON_FILE"
                  :input="input"
                  v-on:file:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-json-file>
                <contilt-zip-file
                  v-if="input.type == inputTypes.FOLDER_UPLOAD"
                  :input="input"
                  v-on:file:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-zip-file>
                <contilt-textarea
                  v-if="
                    input.type == inputTypes.TEXTAREA ||
                    input.type == inputTypes.STRING
                  "
                  :input="input"
                  v-on:textarea:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-textarea>

                <contilt-checkbox
                  v-if="input.type == inputTypes.CHECKBOX"
                  :input="input"
                  v-on:checkbox:change="onInputchanged(input, $event)"
                >
                </contilt-checkbox>

                <contilt-select
                  v-if="input.type == inputTypes.SELECT"
                  :input="input"
                  label="key"
                  :options="input.options"
                  v-on:select:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-select>

                <contilt-multiselect
                  v-if="input.type == inputTypes.MULTISELECT"
                  :input="input"
                  v-on:multiselect:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-multiselect>

                <contilt-radiolist
                  v-if="input.type == inputTypes.RADIOLIST"
                  :input="input"
                  v-on:radiolist:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-radiolist>

                <contilt-checkboxlist
                  v-if="input.type == inputTypes.CHECKBOXLIST"
                  :input="input"
                  v-on:checkboxlist:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-checkboxlist>

                <contilt-formtag
                  v-if="input.type == inputTypes.FORMTAG"
                  :input="input"
                  v-on:formtag:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-formtag>

                <contilt-switch
                  v-if="input.type == inputTypes.SWITCH"
                  :input="input"
                  v-on:switch:change="onInputchanged(input, $event)"
                  :value="resultsData[input.name]"
                >
                </contilt-switch>
              </div>
            </b-col>
          </b-form>
          <div style="text-align: center" v-else>
            <b-spinner type="grow" label="Loading..." variant="primary" />
          </div>
          <b-button
            v-if="generate"
            id="formButton"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="$emit('on-generate', resultsData)"
            block
            :disabled="generationLoading || !isValidQuota"
          >
            <b-spinner v-if="generationLoading" small />
            {{ generateLabel || (isValidQuota ? "Generate" : "No Enough Credits") }}
          </b-button>
        </b-tab>
        <b-tab title="Free From Information">
          <b-form-group>
          <b-form-textarea
            name="textarea"
            rows="28"
            placeholder="You have the freedom to fill out the brief as you wish, without any limitations or specific guidelines."
            v-model="freeTextVal"
          />
          </b-form-group>
              <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"

            @click="freeTextGenerate"
            block
            :disabled="freeTextLoading || generationLoading || !isValidQuota"
          >
            <b-spinner v-if="freeTextLoading || generationLoading" small />
            {{(isValidQuota ? "Generate" : "No Enough Credits") }}
          </b-button>
        </b-tab>
      </b-tabs>

    </b-card>
  </div>
</template>

<script>
import { inputTypes } from "../../../../contilt/configs/inputconstants";
import {
  BCol,
  BCard,
  BCardTitle,
  BForm,
  BButton,
  BSpinner,
  BTab,
  BTabs,
  BFormTextarea,
  BFormGroup
} from "bootstrap-vue";
import ContiltInput from "../../../../contilt/inputelements/input/Contiltinput.vue";
import ContiltImage from "../../../../contilt/inputelements/image/ContiltImage.vue";
import ContiltCsvFile from "../../../../contilt/inputelements/file/ContiltCsvFile.vue";
import ContiltJsonFile from "../../../../contilt/inputelements/file/ContiltJsonFile.vue";
import ContiltZipFile from "../../../../contilt/inputelements/file/ContiltZipFile.vue";
import ContiltTextarea from "../../../../contilt/inputelements/textarea/contilttextarea.vue";
import ContiltSelect from "../../../../contilt/inputelements/select/select.vue";
import ContiltMultiselect from "../../../../contilt/inputelements/multiselect/multiselect.vue";
import ContiltFormtag from "../../../../contilt/inputelements/formtag/formtag.vue";
import contiltCheckbox from "../../../../contilt/inputelements/checkbox/checkbox.vue";
import contiltRadiolist from "../../../../contilt/inputelements/radoilist/radiolist.vue";
import contiltSwitch from "../../../../contilt/inputelements/switch/contiltswitch.vue";
import contiltCheckboxlist from "../../../../contilt/inputelements/checkboxlist/checkboxlist.vue";
import ForInput from "../../../../contilt/inputelements/input/ForInput.vue";
import Ripple from "vue-ripple-directive";
import { mapState, mapActions } from "vuex";
import axios from '@axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  directives: {
    Ripple,
  },
  components: {
    BCol,
    BForm,
    BCard,
    BButton,
    BSpinner,
    BCardTitle,
    ContiltInput,
    ContiltImage,
    ContiltTextarea,
    ContiltSelect,
    ContiltMultiselect,
    ContiltFormtag,
    contiltCheckbox,
    contiltRadiolist,
    contiltSwitch,
    contiltCheckboxlist,
    ContiltCsvFile,
    ContiltJsonFile,
    ContiltZipFile,
    ForInput,
    BTab,
    BTabs,
    BFormTextarea,
    BFormGroup
  },
  props: [
    "includeFreeText",
    "title",
    "generateLabel",
    "generationLoading",
    "generate",
    "sampleinputs",
    "userQuota",
    "entityReaders",
    "freeText"
  ],

  data() {
    return {
      inputTypes: inputTypes,
      tabIndex: 0,
      freeTextVal: "",
      freeTextLoading: false
    };
  },
  created(){
    this.freeTextVal = this.freeText;
  },
  updated() {
    this.$refs.formButton && this.$refs.formButton.focus();
  },
  methods: {
    freeTextGenerate(){
      const body = {
        config: {
          attributesMap: {}
        },
        context: {
          sentences: this.freeTextVal.split(/[\n\.]+/g)
        }
      }
      debugger;
      this.sampleinputs.forEach(x => {
        body.config.attributesMap[x.name] = x.label + (` ${x.configsMap && x.configsMap.documentation ? x.configsMap.documentation: ""}`) + " keep it empty if not exist"
      })
      this.freeTextLoading = true;
      this.resultsData["_freeTextInput"] = this.freeTextVal;
      axios.post(`pipeline/attrExtract`, body).then((res) => {
        Object.keys(res.data).forEach(x=> {
          if(res.data[x]){
            if(!this.resultsData[x]){
              this.resultsData[x] = res.data[x];
            }
            
          }
        })
        
        this.$emit('on-generate', this.resultsData)
        this.freeTextLoading = false
      }).catch((err) => {
        this.freeTextLoading = false;
        this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error happened",
              icon: "AlertTriangleIcon",
              variant: "light-danger",
              text: `${err.response.data.error || err.message}`,
            },
          });
      })
     
    },
    onInputchanged(input, val) {
      this.resultsData[input.name] = val;
      this.$emit("on-input-changed");
    },
  },
  computed: {
    ...mapState({
      selectedEntity: (state) => state.entityStore.selectedEntity,
    }),
    isValidQuota() {
      return this.userQuota > 0;
    },

    resultsData() {
      let result = {};
      let length = this.sampleinputs ? this.sampleinputs.length : 0;
      let i = 0;
      if (this.sampleinputs) {
        for (i; i < length; i++) {
          switch (this.sampleinputs[i].type) {
            case inputTypes.CHECKBOX:
            case inputTypes.SWITCH:
              result[this.sampleinputs[i].name] = this.sampleinputs[i].value
                ? this.sampleinputs[i].value
                : false;
              break;
            case inputTypes.CHECKBOXLIST:
            case inputTypes.FORMTAG:
            case inputTypes.MULTISELECT:
            case inputTypes.RADIOLIST:
              result[this.sampleinputs[i].name] = this.sampleinputs[i].value
                ? this.sampleinputs[i].value
                : [];
              break;
            default:
              result[this.sampleinputs[i].name] = this.sampleinputs[i].value
                ? this.sampleinputs[i].value
                : "";
          }
        }

        if (this.entityReaders && Object.keys(this.entityReaders)) {
          //empty/reset fields
          Object.keys(this.entityReaders).map((key) => {
            if (!this.selectedEntity[key]) {
              return;
            }
            const values = this.selectedEntity[key].values;
            const keysMapping = this.entityReaders[key];

            if (values) {
              // if existed in the entity store
              Object.keys(keysMapping).forEach((k) => {
                if (typeof result[keysMapping[k]] == "string") {
                  result[keysMapping[k]] = "";
                }
              });
            }
          });
        }

        if (this.entityReaders && Object.keys(this.entityReaders)) {
          Object.keys(this.entityReaders).forEach((key) => {
            if (!this.selectedEntity[key]) {
              return;
            }
            const values = this.selectedEntity[key].values;
            if (values) {
              // if existed in the entity store
              const keysMapping = this.entityReaders[key];
              Object.keys(keysMapping).map((val) => {
                if (typeof values[val] === "string") {
                  result[keysMapping[val]] += values[val];
                } else {
                  result[keysMapping[val]] = values[val];
                }
              });
            }
          });
        }
      }
      return result;
    },
  },
};
</script>
<style>
.without-freestyle .input-switch .nav-tabs {
  display: none !important;
}
</style>