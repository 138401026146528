<template>
  <b-form-group :label="input.label">

      <b-form-file
        @change="onFileUploads"
        accept=".csv"
      />
  </b-form-group>
</template>
<script>
import { BFormFile, BFormGroup } from "bootstrap-vue";
import { readFile } from "@core/file/csvFile";

export default {
  components: {
    BFormFile,
    BFormGroup,
  },
  props: ["input"],

  methods: {
    async onFileUploads(event){
        let res = "";
        if(event.target.files && event.target.files.length > 0){
            
            try{
                res = await readFile(event.target.files[0])
            }catch(e){
                console.log(e);
            }

            
            
        }
        this.$emit("file:change", res);
        console.log(event)
    }
  }
};
</script>
