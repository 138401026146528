<template>
    <b-modal
    
      ref="regenerate-modal"
      ok-variant="outline-secondary"
      ok-only
      ok-title="Close"
      centered
      size="lg"
      title="Generate"
      @hidden="resetModal"
    >
<b-card
v-if="output"
    class="chat-widget"
    no-body
  >
    <b-card-header>
      <div class="d-flex align-items-center">
        <b-avatar
          size="34"
          :text="avatarText('Contilt')"
          class="mr-50 badge-minimal"
          variant="light-success"
          badge
          badge-variant="success"
        />
        <h5 class="mb-0">
          {{output._label || output.label || output.name}}
        </h5>
      </div>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
      />
    </b-card-header>

    <section class="chat-app-window">
      <!-- User Chat Area -->
      <vue-perfect-scrollbar
        ref="refChatLogPS"
        :settings="perfectScrollbarSettings"
        class="user-chats scroll-area"
      >
        <chat-log
          :hideAvatar="true"
          :chat-data="chatData"
          :profile-user-avatar="require('@/assets/images/avatars/10.png')"
        />
      </vue-perfect-scrollbar>

      <!-- Message Input -->
      <b-form
        class="chat-app-form"
        @submit.prevent="sendMessage"
      >
        <b-input-group class="input-group-merge form-send-message mr-1">
          <b-form-input
            v-model="chatInputMessage"
            placeholder="Enter your message"
          />
        </b-input-group>
        <b-button
          variant="primary"
          type="submit"
          :disabled="generating"
        >
          Send
        </b-button>
      </b-form>
    </section>
  </b-card>

    </b-modal>
</template>
<script>
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BFormTextarea, BCard, BCardHeader, BAvatar, BInputGroup,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from '@/views/apps/chat/ChatLog.vue' 
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

import axios from '@axios'
export default {
      components: {
    BButton,
    BFormTextarea,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BCard, BCardHeader, BAvatar, BInputGroup,
    VuePerfectScrollbar,
    ChatLog
  },
  props: ["forIndex", "sessionId", "dynamicOutputId"],
    directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data: () => ({
    generating: false,
    outputHistory: false,
    avatarText: avatarText,
          perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    chatInputMessage: "",
    itemName: null,
    output: null,


          chatData: {
        chat: {
          id: 2, userId: 1, unseenMsgs: 0, chat: [],
        },
        contact: {
          id: 1,
          fullName: 'Felecia Rower',
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/1.png'),
          status: 'away',
        },
      },
  }),
  methods: {
    async guidanceRegenerate(output, comment, itemName ){

 
      const res = await axios.post("/templates/guidanceGenerate", {targetOutput: output.nodeId, forIndex: this.forIndex, outputItemName: itemName, templateCallId: this.sessionId, dynamicOutputId: this.dynamicOutputId, comment, outputHistory: this.outputHistory});
      return res.data;
     
    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight + 1500;
    },
    async sendMessage(){
      this.generating = true;
      let qMessage = {
            message: this.chatInputMessage,
            time: new Date(),
            role: "user",
            senderId: 1,
            loading: false
          }
      
      this.chatData.chat.chat.push(qMessage)
      let aMessage = {
            message: "",
            time: new Date(),
            role: "assistant",
            senderId: 11,
            loading: true
          }
      this.chatData.chat.chat.push(aMessage)
      setTimeout(() => {
        this.psToBottom();
      }, 1);
      const comment = this.chatInputMessage;
      this.chatInputMessage = "";
      try {
        const mres= await this.guidanceRegenerate(this.output, comment, this.itemName)
        
        aMessage.message = mres;
        aMessage.loading = false;
        setTimeout(() => {
          this.psToBottom();
        }, 1);
        this.$emit("on-regenerate", this.output, this.itemName, mres);
      }catch(e){
        this.chatData.chat.chat.pop();
      }

      if(this.outputHistory){
          await axios.patch(`/outputs/${this.outputHistory}`, {data: this.chatData.chat.chat, outputId: this.output.nodeId, session: this.sessionId})
      }else {
        const history = await axios.post(`/outputs`, {data: this.chatData.chat.chat, outputId: this.output.nodeId, session: this.sessionId})
        this.outputHistory = history.data;
      }
      this.generating = false;
      
    },
    showModal(output, itemName, value, callback) {
      debugger;
      this.output = output
      this.itemName = itemName
      this.callback = callback
      this.chatData.chat.chat.push({
        message: value,
        time: new Date(),
        role: "assistant",
        senderId: 11,
        loading: false
      })
      this.$refs["regenerate-modal"].show();
      axios.get("/outputs", {params: {outputId: this.output.nodeId, session: this.sessionId}}).then((d) => {
        if(d.data && d.data.results.length > 0){
          this.outputHistory = d.data.results[0].id;
          this.chatData.chat.chat = d.data.results[0].data;
          setTimeout(() => {
            this.psToBottom();
          }, 10);
          
        }
      })
      setTimeout(() => {
        this.psToBottom()
      }, 100);
       
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()

      return valid
    },
    resetModal() {

      this.chatData.chat.chat = []

    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
   
    },

  }
}
</script>
<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>