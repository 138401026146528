<template>
    <div>
      <div :key="index" v-for="(item, index) in value" >
        <contilt-title v-if="label" :value="`${label} ${index + 1}`"></contilt-title>
            <ContiltOutput
            @on-save="$emit('on-save', $event)"
            :fromFor="true"
            :key="index"
            :forIndex="index"
            :dynamicOutputId="dynamicOutputId"
            :outputStructure="item['_outputstructure']" 
            :outputsFromSer="item" />
      </div>

    </div>
</template>
  
  <script>
  import ContiltTitle from "../../../contilt/outputelements/maintitle/maintitle.vue";

  export default {

    components: { 
        ContiltTitle,
        ContiltOutput: () => import('../../../views/pages/main/outputsfactory/outputsfactory.vue')
     },
    props: ["value", "label", "dynamicOutputId"],
   
  };
  </script>
  