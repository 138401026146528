<template>
  <div class="contilt-dynamic-outputs" v-if="template">
        <div>
          <DynamicGenerateModal v-if="inputsResultsData " :template="template" :taskId="taskCallLogId" :templateCallId="templateCallLogId" @on-dynamic-regenerate="dynamicGenerate" ref="dynamic-generate" :baseTemplate="currentTemplate" :baseInputs="inputsResultsData" :dynamicOutput="dynamicOutput" :dynamicOutputData="dynamicOutputDataForNode" />
          <ContiltOutput
            v-if="dynamicOutputData && outputStructure"
            :templateId="template.id"
            :taskId="taskCallLogId"
            :templateCallId="templateCallLogId"
            :outputStructure="outputStructure"
            :outputsFromSer="pageOutputData"
            :dynamicOutputId="dynamicOutputId"

          />
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            v-if="!generationLoading"
            @click="showDynamicGenerate()"
            block
            :disabled="dynamicOutputId"
          >
            <b-spinner v-if="dynamicOutputId" small />
            {{dynamicOutput.buttonActionName || "+"}}
          </b-button>
        </div>

            
            

  </div>
  
</template>

<script>

import DynamicGenerateModal from "@core/components/modals/DynamicGenerateModal.vue";
import { BRow, BCol, BImg, BOverlay, BButton, BSpinner } from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import axios from '@axios';
export default {
    props: ["dynamicOutputData", "dynamicOutput", "currentTemplate", "templateCallLogId", "taskCallLogId", "inputsResultsData", "generationLoading"],
    components: {BRow, BCol, BImg, BOverlay, BButton, BSpinner, DynamicGenerateModal, ContiltOutput: () => import('./outputsfactory/outputsfactory.vue')},
    data(){
      return {
        template: null,
        outputStructure: null,
        pageOutputData: null,
        pageOutputDataKey: null,
        dynamicOutputId: null
      }
    },
    computed: {
      dynamicOutputDataForNode(){
        return this.dynamicOutputData.filter(x=>x.outputId == this.dynamicOutput.nodeId)
      }
    },
    methods: {
        showDynamicGenerate(){
            this.$refs['dynamic-generate'].showModal()
        },
        dynamicGenerate({resultsData, nodeId}){
              this.pageOutputDataKey = uuidv4()
              let self = this;
              let useSocket = true;
              setTimeout(() => {
                this.pageOutputData = null;
                this.dynamicOutputId = uuidv4();
                const dynamicOutputStructure = JSON.parse(JSON.stringify(this.template.outputs))
                this.outputStructure = dynamicOutputStructure

            
                if (useSocket) {
                  const innerData = {};
                  if(dynamicOutputStructure){
                    dynamicOutputStructure.forEach((x) => {
                    if (x.type == "multifield") {
                      innerData[x.name] = {};
                      x.loading = true;
                      x.items.forEach((y) => {
                        innerData[x.name][y.name] = {
                          value: "",
                          loading: true,
                        };
                      });
                    } else if(x.name) {
                      innerData[x.name] = {
                        value: "",
                        loading: true,
                      };
                    }
                  });
                  }
                  this.pageOutputData = innerData;
                }
               
                this.$emit("dynamic-generate", {resultsData, template: this.template, dynamicOutputId: this.dynamicOutputId, nodeId})
               
                
              }, 10);



            },
            
    },
    created(){
        axios.get(`templates/${this.dynamicOutput.templateId}`).then((template) => {
          this.template = template.data;
        })

    },
    watch: {
      dynamicOutputData(){
        if(this.dynamicOutputData.find(s => s.dynamicOutputId == this.dynamicOutputId)){
          this.dynamicOutputId = null;
          this.pageOutputData = null;
          this.pageOutputDataKey = uuidv4()
        }
      }
    }
};
</script>

<style scoped>
  .contilt-dynamic-outputs {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.03);
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 22px;
    border-radius: 5px;
  }
</style>