<template>
    <b-form-group :label="input.label">
    
      <div :key="index" v-for="(itemm, index) in items">
        <b-card style="background: #efefef">
          <div
            style="position: absolute; top: 0px; right: 0px; overflow: visible"
          >
            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
        <div :key="index2" v-for="(item, index2) in itemm">
            
            <b-form-group v-if="item.type == 'text' || item.type == 'input'" :label="`${item.label}  ${index + 1}`">
                <b-form-input
                v-model="item.value"
                >
                </b-form-input>
            </b-form-group>
            <b-form-group v-else-if="item.type == 'textarea'" :label="`${item.label}  ${index + 1}`">
                <b-form-textarea
                v-model="item.value"
                >
                </b-form-textarea>
            </b-form-group>
            
        </div>
        </b-card>

      </div>
        
    
    <b-button variant="primary" @click="addMore" block>+</b-button>
    </b-form-group>
</template>
<script>
import { BFormInput, BFormGroup, BCard, BCardTitle, BFormTextarea, BButton } from "bootstrap-vue";

export default {
    data(){
        return {
            items: []
        }
        
    },
    methods: {
        addMore(){
             this.items.push(JSON.parse(JSON.stringify(this.input.items)))
        },
        removeItem(index){
            this.items.splice(index, 1)
        }
    },
  components: {
    BFormInput,
    BFormGroup,
    BCard, BCardTitle, BFormTextarea, BButton
  },
  props: ["input"],
  created(){
    let items = [];
    if(this.input.value){
      for(let i=0; i< this.input.value.length; i++){
          const currentVal = this.input.value[i];
          const objData = JSON.parse(JSON.stringify(this.input.items));
          objData.forEach(x => {
            if(currentVal[x.name]){
              x.value = currentVal[x.name]
            }
          })
          items.push(objData)
      }
    }else {
      const itemsSize = this.input.configsMap.defaultListSize || 1;
      for(let i=0; i<itemsSize; i++){
          items.push(JSON.parse(JSON.stringify(this.input.items)))
      }
      
    }
this.items = items;

  },
  watch: {
    items: {
        deep: true,
        handler(newValue) {
            const listValue = [];
            newValue.forEach(element => {
                const obj = {};
                element.forEach(x => {
                    obj[x.name] = x.value
                })
                listValue.push(obj);
            });
            this.$emit("forInput:change", listValue)
        }
    }

  }
    

};
</script>
