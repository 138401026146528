import csv from 'jquery-csv';

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
        const text = processData(e.target.result);
        resolve(text);
     };
     reader.readAsText(file);
  });
}

function CSVtoArray(text) {
    var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    // Return NULL if input string is not well formed CSV string.
    if (!re_valid.test(text)) return null;
    var a = [];                     // Initialize array to receive values.
    text.replace(re_value, // "Walk" the string using replace with callback.
        function(m0, m1, m2, m3) {
            // Remove backslash from \' in single quoted values.
            if      (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
            // Remove backslash from \" in double quoted values.
            else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
            else if (m3 !== undefined) a.push(m3);
            return ''; // Return empty string.
        });
    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) a.push('');
    return a;
};

export const processData = (allText) => {
    debugger;

    let allTextLines = allText.split(/\r\n|\n/).map(x=>x.trim()).filter(x=>x.length>0);
    let entries = allTextLines[0].split(/[,;]/g).map(x=>x.trim());
    
    let sheetData = [];
    for(let i=1; i<allTextLines.length; i++){
        let raw = allTextLines[i].split(";");
        if(raw.length ==0 || raw.length == 1){
            sheetData = csv.toObjects(allText)
            break
    
        }
        
        const rawData = {};
        for(let j=0;j<raw.length;j++){
            let element = raw[j].trim();
            if(element.startsWith("\" ") && element.endsWith(" \"")){
                element = element.substring(2);
                element = element.substring(0, element.length -2);
            }
            rawData[entries[j]] = element.replace(/""/g, "\"")
        }
        sheetData.push(rawData);
    }
   
    return sheetData;
};
