<template>
    <div>
        <div :key="item.id" v-for="item in dynamicOutputData">
            <ContiltOutput
              :dynamicOutputId="item.dynamicOutputId"
              :templateId="template.id"
              :taskId="taskId"
              :templateCallId="templateCallId"
              :outputStructure="outputStructure"
              :outputsFromSer="item.data.outputs"
              :main="true"
            />
        </div>
    </div>
</template>
<script>

export default {
    props: ["dynamicOutputData", "taskId", "templateCallId", "template", "dynamicOutputData"],
    components: {ContiltOutput: () => import('./outputsfactory/outputsfactory.vue')},
    computed : {
        outputStructure(){
            return this.template.outputs;
        }
    }
}
</script>