<template>

  <b-modal
    no-close-on-backdrop
    class="report-modal"
      ref="report-modal"
      title="Reports"
       size="lg"
      ok-only
      ok-variant="dark"
      ok-title="Cancel"
      centered
    >
      <UserReportList @on-report="$emit('on-report', true)" :template="template" :outputId="outputId" :session="session" />
    </b-modal>


</template>

<script>

import { BNavItem, BModal, BTabs, BTab } from 'bootstrap-vue';
import UserReportList from "../../../views/pages/contilt-pages/report/user-report/user-report-list/UserReportList.vue";

export default {
  components: {
    UserReportList,
    BNavItem,
    BModal,
    BTabs, BTab,
  },
  data() {
    return{
      template: null,
      outputId: null,
      session: null,
      reports: [],
    }
  },
  methods: {
    showModal(template, outputId, session) {
      this.template = template
      this.outputId = outputId
      this.session = session
      this.$refs["report-modal"].show();
    },

  }
 
}
</script>
<style>
  .modal-lg {
    max-width: 1000px !important;
  }
</style>