import router from '@/router'
export default function(actions){
    actions.forEach(action => {
        if(action.type == "redirect"){
            if(action.url){
                window.open(action.url);
            }
            if(action.templateData){
                router.push({name: "template-to-run", params: {id: action.templateId, templateData: action.templateData}, query: null})
            }
        }
        else if(action.type == "inapp"){
            router.push({name: "in-app", params: {id: action.inAppToken}})
        }
    });
  
}