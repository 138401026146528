<template>
  <div :data-name="item.name" @mouseover="mouseHover = true" @mouseleave="mouseHover = false" :class="{'output-renderer-mouse-hover' : mouseHover}" class="output-renderer">
    <b-row v-if="values && values">
      <b-col lg="12">
        <b-col lg="12">
          <loading-data
            v-if="values && values.loading ? values.loading : false"
          />

          <contilt-title
            v-if="item.type == outputTypes.MAINTITLE"
            :item="item"
            :value="values.value"
          ></contilt-title>

          <contilt-sub-title
            v-if="item.type == outputTypes.SUBTITLE"
            :value="values.value"
          ></contilt-sub-title>

          <contilt-text
            v-if="item.type == outputTypes.TEXT && values.value"
            :item="item"
            :dynamicOutputId="dynamicOutputId"
            @on-save="$emit('on-save', $event)"
            :value.sync="values.value"
          ></contilt-text>
          <contilt-imagelink
            v-if="item.type == outputTypes.IMAGELINK"
            :value="{ value: item.value }"
          ></contilt-imagelink>
          <contilt-imagebase
            v-if="item.type == outputTypes.IMAGECARDBASE64"
            :value="item.value"
          ></contilt-imagebase>

          <text-list
            v-if="values && values.value && item.type == outputTypes.TEXTLIST"
            :item="item"
            :value="values.value"
          >
          </text-list>

          <contilt-imagecard
            v-if="
              item.type == outputTypes.IMAGELINKCARD
            "
            :info="{value: values.value}"
          >
          </contilt-imagecard>
          <div v-if="item.type == 'forOutput'">
            <contilt-for-output
              :dynamicOutputId="dynamicOutputId"
              @on-save="$emit('on-save', $event)"
              :label="values.label || values._label"
              :value="values.value"
            >
            </contilt-for-output>
          </div>


          <contilt-input
            v-if="
              item.type == outputTypes.INPUT && outputUpdatedData[item.name]
            "
            :value="values.value"
            :input="{ label: outputUpdatedData[item.name].label }"
          >
          </contilt-input>
        </b-col>
        <hr v-if="item.type == outputTypes.SPLITTER" />
        <div class="simple-chat" v-if="!generationLoading">

          <SimpleChat @show-history="$emit('show-history')" @on-chat-generate="onGenerate" :outputId="outputId" :forIndex="forIndex" :dynamicOutputId="dynamicOutputId" :itemName="item.name" :value="values.value"/>

        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";
import contiltText from "../text/text.vue";
import contiltTitle from "../maintitle/maintitle.vue";
import contiltSubTitle from "../subtitle/subtitle.vue";
import contiltImagelink from "../imagelink/imagelink.vue";
import contiltImagebase from "../imagebase64/imagebase.vue";
import { outputTypes } from "../../configs/inputconstants";
import TextList from "../textlist/textlist.vue";
import LoadingData from "../loading/loading-data";
import ContiltInput from "../../../contilt/inputelements/input/Contiltinput.vue";
import contiltImagecard from "../imagecard/imagecard.vue";
import ContiltForOutput from "../foroutput/ContiltForOutput.vue"
import SimpleChat from '@/@core/components/global/SimpleChat.vue';
export default {
  components: {
    BRow,
    BCol,
    contiltText,
    contiltTitle,
    contiltImagelink,
    contiltImagebase,
    TextList,
    LoadingData,
    contiltSubTitle,
    contiltImagecard,
    ContiltInput,
    ContiltForOutput,
    SimpleChat,
    BButton,
  },
  data(){
    return {
      mouseHover: false
    }
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: ["values", "item", "dynamicOutputId", "outputId", "forIndex", "generationLoading"],
  methods: {
    onGenerate(outputId, itemName, mres){
      this.$emit("on-chat-generate", this.item, itemName, mres)
    }
  },
  computed: {
        sessionId() {
      return this.$route.query.sessionId;
    },
    outputTypes() {
      return outputTypes;
    },
  },
};
</script>

<style scoped>
  .simple-chat{
    display: flex;
    width: 100%;
    align-items: center;
  }
</style>