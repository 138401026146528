<template>
  <b-card-text class="mt-1 mb-1">
    <p contenteditable="true" v-html="currentValue" @input="onInput"></p>
    <b-button class="save-on-edit" @click="save" v-if="inputChanges" variant="primary">
      Save
      <b-spinner v-if="loading" small />
    </b-button>
  </b-card-text>
  
</template>

<script>
import { BCardText, BButton, BSpinner } from "bootstrap-vue";

export default {
  components: { BCardText, BButton, BSpinner },
  data() {
    return {
      currentValue: "",
      newValue: "",
      inputChanges: false,
      loading: false
    }
    
  },
  props: ["item", "value"],
  mounted(){
    this.currentValue = this.value.replace(/\n/g, "<br>")
  },
  methods: {
    save(){
      this.loading = true;
      this.$emit('on-save', {oldValue: this.currentValue.replace(/<br>/g, "\n"), newValue: this.newValue});
      let _this = this;
      setTimeout(function() {
        _this.inputChanges = false;
        _this.loading = false;
      }, 1000)
      
    },
    onInput(e){
      this.inputChanges = true;
      this.newValue = e.target.innerText;
      this.$emit("update:value", e.target.innerText)
    }
  },
  computed: {
    sessionId(){
      return this.$route.query.sessionId;
    }
  }
};
</script>

<style scoped>
  p {
    outline: 0px solid transparent;
  }
  .generation-loading .save-on-edit {
    display: none !important;
  }
</style>