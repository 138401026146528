<template>
    <div v-if="template" class="sub-template-output">
        <loading-data v-if="!values" />
        <b-card-title>{{ subtemplateOutput.label }}</b-card-title>
          <ContiltOutput
            :key="triggerKey"
            v-if="pageOutputData"
            :forIndex="subtemplateOutput.nodeId"
            ref="subtemplateOutputs"
            :template="template"
            :templateId="template.id"
            :taskId="taskId"
            :templateCallId="templateCallId"
            :generationLoading="generationLoading"
            :dynamicOutputData="dynamicOutputData"
            :outputStructure="outputStructure"
            :outputsFromSer="pageOutputData"
            :fromFor="true"
            :inputsResultsData="pageOutputData['_inputs']"
            @dynamic-generate="$emit('dynamic-generate', $event)"
            @on-save="$emit('on-save', $event)"
          />
    
    </div>
</template>

<script>
import axios from '@axios';
import {
  BCard,
  BCardTitle,

} from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import loadingData from '@/contilt/outputelements/loading/loading-data.vue';
export default {
    props: ["subtemplateOutput", "values", "triggerKey", "taskId", "templateCallId", "dynamicOutputData", "generationLoading"],
    data(){
        return {
            template: null,
            pageOutputData: null
        }
    },
    components: {BCard, BCardTitle, loadingData, ContiltOutput: () => import('./outputsfactory/outputsfactory.vue')},
    created(){

        let self = this;

        if(this.subtemplateOutput.structure){
            this.template = {
                id: this.subtemplateOutput.structure.templateId,
                outputs: this.subtemplateOutput.structure.outputs
            } 
            self.pageOutputData = self.values ? self.values.value : {};
            // this.fillPageOutputData(self.outputStructure, self.pageOutputData);
            return;
        }
        axios.get(`templates/${this.subtemplateOutput.templateId}`).then((template) => {
            this.template = template.data;
            debugger;
            let useSocket = true;
            if(!self.values.loading){
                self.pageOutputData = self.values.value;
                return
            }
            if (useSocket) {
                self.pageOutputData = self.values.value;
                this.fillPageOutputData(self.outputStructure, self.pageOutputData)
          
            
            }

        })
    },
    watch: {
        triggerKey(){
                const newvalues = this.values;
                if(newvalues&& newvalues.value){
                    Object.keys(newvalues.value).forEach(x=> {
                        if(this.pageOutputData[x]){
                             this.pageOutputData[x] = newvalues.value[x]
                        } 
                    })

                    
                    
                }
        },
    },
    computed: {
        outputStructure(){
            return this.template.outputs;
        }
    },
    methods: {
        fillPageOutputData(outputStructure, pageOutputData){
                if(outputStructure){
                    outputStructure.forEach((x) => {
                    if (x.type == "multifield") {
                    pageOutputData[x.name] = {};
                    x.loading = true;
                    x.items.forEach((y) => {
                        pageOutputData[x.name][y.name] = {
                        value: "",
                        loading: true,
                        };
                    });
                    } else if(x.name) {
                    pageOutputData[x.name] = {
                        value: "",
                        loading: true,
                    };
                    }
                });
                }
        }
    }
}
</script>
<style>
    .sub-template-output{
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 15px;
        background-color: rgba(0, 0, 0, 0.03);
    }
    .main-output .card-title {
        font-size: 16px !important;
        
    }

</style>
<style scoped>
    .main-output .card-title {
        color: #a5a5a5;
        
    }
</style>