<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.senderId === formattedChatData.contact.id}"
    >
      <div v-if="!hideAvatar" class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          :variant="msgGrp.senderId === formattedChatData.contact.id ? 'light-info': 'light-primary'"
          :text="msgGrp.senderId === formattedChatData.contact.id ? avatarText('Answer'): avatarText('Question')"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.time.toString()"
          class="chat-content"
        > 
        <b-spinner v-if="msgData.loading"
          class="mr-1"
          variant="info"
        />
          <p v-html="addBrs(msgData.msg)"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar, BSpinner } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BSpinner
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    hideAvatar: {
      required: false
    }
  },
  methods: {
    addBrs(msg){
      return msg.replace(/[\n]+/g, "<br>")
    }
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            loading: msg.loading
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              time: msg.time,
              loading: msg.loading
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
      }
    })

    return {
      formattedChatData,
      avatarText
    }
  },
}
</script>

<style>

</style>
