<template>
  <b-col cols="12" class="mt-2">
    <h6 class="section-label">Send feedback</h6>
    <b-card>
      <b-form>
        <b-row>
          <b-col sm="6">
            <b-form-group class="mb-2">
              <b-form-input name="name" placeholder="Name" v-model="name" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group class="mb-2">
              <b-form-input
                name="email"
                type="email"
                placeholder="Email"
                v-model="email"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col sm="6">
            <b-form-group class="mb-2">
              <b-form-input name="website" placeholder="Website" />
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-form-group class="mb-2">
              <b-form-textarea
                name="textarea"
                rows="4"
                placeholder="Website"
                v-model="text"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="commentCheckmark"
                    name="checkbox-1"
                    class="mb-2"
                  >
                    Save my name, email, and website in this browser for the next time I comment.
                  </b-form-checkbox>
                </b-col> -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="submitReview"
            >
              Send
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BButton,
  BFormTextarea,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      name: "",
      email: "",
      text: "",
    };
  },
  methods: {
    submitReview() {
      // console.log(this.name)
      // console.log(this.email)
      // console.log(this.text)
    },
  },
};
</script>
