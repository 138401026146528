<template>
  <div>
    <DynamicOutputs :dynamicOutputData="dynamicOutputData" :taskId="taskId" :templateCallId="templateCallId" :template="template"/>
    <b-modal
    
      no-close-on-backdrop
      ref="dynamic-generate-modal"
      title="Generate"
      size="lg"
      ok-title="Generate"
      centered
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOk"
    >
          <contilt-inputs

          
            ref="dynamic-contilt-inputs"
            :sampleinputs="inputs"
          ></contilt-inputs>
    </b-modal>
  </div>
  
</template>

<script>
import vSelect from 'vue-select';
import {
    BModal,
  BFormGroup,
} from "bootstrap-vue";
import ContiltInputs from '@/views/pages/main/inputsFactory/inputsFactory.vue';
import axios from '@axios';
import DynamicOutputs from '@/views/pages/main/DynamicOutputs.vue';

export default {
    props: ["template","dynamicOutput", "baseTemplate", "baseInputs", "templateCallId", "taskId", "dynamicOutputData"],
    components: {vSelect, BModal, BFormGroup, ContiltInputs, DynamicOutputs},

  computed: {
    inputs(){
            let _this = this;
      const inputName2Input = {};
      this.baseTemplate.inputs.forEach(element => {
        inputName2Input[element.name] = element;
      });

      const inputs = this.template.inputs.filter(x=>this.dynamicOutput.inputs[x.name])
      inputs.forEach(x => {
        if(inputName2Input[_this.dynamicOutput.inputs[x.name]]){
          x.value = _this.baseInputs[_this.dynamicOutput.inputs[x.name]]
        }
        if( inputName2Input[_this.dynamicOutput.inputs[x.name]] && inputName2Input[_this.dynamicOutput.inputs[x.name]].configsMap["dynamicOutputRequest"] == "hidden"){
          
          x.hidden = true;
        }
      })
      return inputs
    }
  },

  methods: {
    showModal() {
      this.$refs["dynamic-generate-modal"].show();
    },

    resetModal() {

    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
      this.resetModal();
    },
    handleSubmit() {

      this.$emit('on-dynamic-regenerate', {resultsData: this.$refs["dynamic-contilt-inputs"].resultsData, nodeId: this.dynamicOutput.nodeId})
    

      this.$nextTick(() => {
        this.$refs["dynamic-generate-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>