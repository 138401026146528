<template>
  <b-col
    :class="{ 'contilt-output': !isContainFor, 'generation-loading': generationLoading }"
    lg="12"
    v-if="this.outputsFromSer && Object.keys(this.outputsFromSer).length > 0"
  >
    <div
      :class="`inner-output output-${output.nodeId}`"
      :key="index"
      v-for="(output, index) in this.outputStructure"
    >
      <div
        style="position: relative"
        v-if="
          output.type == outputTypes.MULTIFIELD &&
          outputUpdatedData[output.name] && showMultifield(output.items, outputUpdatedData[output.name])
        "
      >
        <div class="contilt-session">
          <b-button
            v-if="!generationLoading"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            v-b-tooltip.hover.v-primary
            title="Report"
            @click="
              $refs['reportModal'].showModal(
                templateId,
                output.nodeId,
                sessionId
              )
            "
            class="btn-icon rounded-circle report"
          >
            <feather-icon
              :badge="
                reportCountByTemplate && reportCountByTemplate[output.nodeId]
              "
              size="18"
              class="text-success"
              badge-classes="badge-success"
              icon="CpuIcon"
            />
          </b-button>

          <b-button
            v-if="!generationLoading && !dynamicOutputId"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            v-b-tooltip.hover.v-primary
            title="Regenerate"
            @click="regenerate(output)"
            class="btn-icon rounded-circle regenerate"
          >
            <feather-icon
              size="18"
              v-if="!regenerateNodes[output.nodeId]"
              icon="RefreshCwIcon"
            />
            <b-spinner v-else small />
          </b-button>
        </div>
        <contilt-multifield
          :forIndex="forIndex"
          @show-history="multifieldRegenerate(output, $event.itemName)"
          @on-save="saveNewOutput($event, output.nodeId, true)"
          :values="outputUpdatedData[output.name]"
          @on-chat-generate="guidanceRegenerate"
          :generationLoading="generationLoading"
          :output="output"
        >
          
        </contilt-multifield>
        
      </div>
      <div v-else-if="output.type == outputTypes.DYNAMIC_OUTPUT">
        <DynamicOutputsGenerate :generationLoading="generationLoading" :dynamicOutputData="dynamicOutputData" :dynamicOutput="output" :currentTemplate="template" :templateCallLogId="templateCallId" :taskCallLogId="taskId" :inputsResultsData="inputsResultsData" @dynamic-generate="$emit('dynamic-generate', $event)" />
      </div>
      <div v-else-if="output.type == outputTypes.SUBTEMPLATE_OUTPUT && outputUpdatedData[output.name]">
        <SubtemplateOutput :triggerKey="subTemplateKey" @on-save="saveNewOutput($event, output.nodeId, $event.manualEditing)" @dynamic-generate="$emit('dynamic-generate', $event)" :generationLoading="generationLoading" :dynamicOutputData="dynamicOutputData" :templateCallId="templateCallId" :taskId="taskId" :subtemplateOutput="output" :values="outputUpdatedData[output.name]" />
      </div>
      <div v-else-if="output.type == 'agentTemplate' && outputUpdatedData[output.name]">
          <DependedOutput :key="output.triggerKey ? (output.triggerKey + pathTemplateKey): pathTemplateKey" :triggerKey="output.triggerKey ? (output.triggerKey + pathTemplateKey): pathTemplateKey" @on-save="saveNewOutput($event, output.nodeId, $event.manualEditing)" @dynamic-generate="$emit('dynamic-generate', $event)" :generationLoading="generationLoading" :dynamicOutputData="dynamicOutputData" :templateCallId="templateCallId" :taskId="taskId" :dependedOutput="output" :values="outputUpdatedData[output.name]" />
      </div>
      <div
        class="contilt-output-for"
        v-else-if="
          outputUpdatedData[output.name] &&
          output.type == outputTypes.FOR_OUTPUT
        "
      >
        <contilt-outputs-renderer
          :fromFor="true"
          :forIndex="forIndex"
          :dynamicOutputId="dynamicOutputId"
          @on-save="saveNewOutput($event, output.nodeId, true)"
          :values="outputUpdatedData[output.name]"
          :item="output"
        ></contilt-outputs-renderer>
      </div>
      <b-card v-else-if="outputUpdatedData[output.name] && output.type && (outputUpdatedData[output.name].value || (outputUpdatedData[output.name].loading && !outputUpdatedData[output.name].value))">
        <div class="contilt-session">
          <b-button
            v-if="!generationLoading"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            v-b-tooltip.hover.v-primary
            title="Report"
            @click="
              $refs['reportModal'].showModal(
                templateId,
                output.nodeId,
                sessionId
              )
            "
            class="btn-icon rounded-circle report"
          >
            <feather-icon
              :badge="
                reportCountByTemplate && reportCountByTemplate[output.nodeId]
              "
              size="18"
              class="text-success"
              badge-classes="badge-success"
              icon="CpuIcon"
            />
          </b-button>

          <b-button
            v-if="!generationLoading && !dynamicOutputId"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            v-b-tooltip.hover.v-primary
            title="Regenerate"
            @click="regenerate(output)"
            class="btn-icon rounded-circle regenerate"
          >
            <feather-icon
              size="18"
              v-if="!regenerateNodes[output.nodeId]"
              icon="RefreshCwIcon"
            />
            <b-spinner v-else small />
          </b-button>
        </div>
        <contilt-title
          :value="
            outputUpdatedData[output.name]['_label']
              ? outputUpdatedData[output.name]['_label']
              : outputUpdatedData[output.name]['label']
          "
          v-if="
            outputUpdatedData[output.name] &&
            (outputUpdatedData[output.name]['_label'] ||
              outputUpdatedData[output.name]['label'])
          "
        >
        </contilt-title>

        <contilt-outputs-renderer
          @show-history="$refs['guidanceRegenerateModal'].showModal(output,null, outputUpdatedData[output.name].value)"
          :dynamicOutputId="dynamicOutputId"
          :generationLoading="generationLoading"
          @on-save="saveNewOutput($event, output.nodeId, true)"
          :values="outputUpdatedData[output.name]"
          :outputId="output.nodeId"
          :forIndex="forIndex"
          :item="output"
          @on-chat-generate="guidanceRegenerate"
        >
        </contilt-outputs-renderer>
      </b-card>
    </div>
    <report-show ref="reportModal" @on-report="getReportCountByTemplate">
    </report-show>
    <guidance-regenerate :forIndex="forIndex" :dynamicOutputId="dynamicOutputId" :sessionId="sessionId" ref="guidanceRegenerateModal" @on-regenerate="guidanceRegenerate">
    </guidance-regenerate>
  </b-col>
</template>

<script>
import {
  BCol,
  BCard,
  BButton,
  BSpinner,
  BFormGroup,
  BFormTextarea,
  VBTooltip
} from "bootstrap-vue";
import { outputTypes } from "../../../../contilt/configs/inputconstants";
import contiltMultifield from "../../../../contilt/outputelements/multifield/multifield.vue";
import ContiltOutputsRenderer from "../../../../contilt/outputelements/putputsRenderer/outputsRenderer.vue";
import SocketioService from "../socketio.service";
import { onBeforeUnmount } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ContiltTitle from "../../../../contilt/outputelements/maintitle/maintitle.vue";
import actionHandler from "../actions/action-handler";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ReportShow from "@/@core/components/modals/ReportShow.vue";
import GuidanceRegenerate from "@/@core/components/modals/GuidanceRegenerate.vue";
import DynamicOutputsGenerate from '../DynamicOutputsGenerate.vue';
import SubtemplateOutput from '../SubtemplateOutput.vue';
import { v4 as uuidv4 } from "uuid";
import DependedOutput from '../DependedOutput.vue';
export default {
  name: "ContiltOutput",
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    contiltMultifield,
    ContiltOutputsRenderer,
    BCol,
    BCard,
    ContiltTitle,
    BButton,
    BSpinner,
    BFormGroup,
    BFormTextarea,
    ReportShow,
    DynamicOutputsGenerate,
    SubtemplateOutput,
    GuidanceRegenerate,
    DependedOutput
  },
  props: [
    "main",
    "outputStructure",
    "template",
    "outputsFromSer",
    "inputsResultsData",
    "taskId",
    "templateCallId",
    "generationLoading",
    "fromFor",
    "forIndex",
    "dynamicOutputId",
    "dynamicOutputData"
  ],
  data() {
    return {
      subTemplateKey: null,
      pathTemplateKey: null,
      outputTypes: outputTypes,
      outputUpdatedData: null,
      regenerateNodes: {},
     
      report: {
        reportMessage: "",
        outputResult: "",
        outputId: null,
      },
      reportState: null,
      reportCountByTemplate: null,
    };
  },


  created() {
    this.outputUpdatedData = this.getOutputUpdatedData();
    if(this.main){
      this.initSocketEvents();
    }
    
    this.regenerateNodes = this.getRegenerateNodes();
    this.getReportCountByTemplate();
  },
  computed: {
    templateId() {
      return this.$route.params.id;
    },
    isContainFor() {
      if (this.outputStructure) {
        return this.outputStructure.find((x) => x.type == "forOutput");
      }
      return false;
    },
    sessionId() {
      return this.$route.query.sessionId;
    },
  },
  methods: {
    multifieldRegenerate(output, itemName){
      this.$refs['guidanceRegenerateModal'].showModal(output, itemName, this.outputUpdatedData[output.name][itemName].value)
    },
    showMultifield(items, values) {
      let show = false;
      if (items.length) {
        const length = items.length;
        let i = 0;
        let item;
        for (i; i < length; i++) {
          item = items[i];
          if ((values[item.name] && values[item.name].value && !values[item.name].loading) || (values[item.name] && values[item.name].loading == true)) {
            show = true;
            break;
          }
        }
      }

      return show;
    },
    saveNewOutput(data, nodeId, manualEditing) {
      if(this.generationLoading){
        return;
      }
      if (!data) {
        data = {};
      }
      if (!data.nodeId) {
        data.nodeId = nodeId;
      }
      data.manualEditing = manualEditing;
      if (!this.sessionId) {
        return;
      }
      let _this = this;

      // if(parentComponent && parentComponent == "for"){
      //   return
      // }

      Object.keys(_this.outputUpdatedData).forEach((x) => {
        if (
          _this.outputUpdatedData[x].value &&
          _this.outputsFromSer &&
          _this.outputsFromSer[x] &&
          _this.outputsFromSer[x].value
        ) {
          _this.outputsFromSer[x].value = _this.outputUpdatedData[x].value;
        }
        else if(_this.outputUpdatedData[x].value && Object.keys(_this.outputUpdatedData[x].value).length > 0){
          Object.keys(_this.outputUpdatedData[x].value).forEach(y => {
            if (
              _this.outputUpdatedData[x].value[y].value &&
              _this.outputsFromSer &&
              _this.outputsFromSer[x] &&
              _this.outputsFromSer[x][y] &&
              _this.outputsFromSer[x][y].value
            ) {
              _this.outputsFromSer[x][y].value =
                _this.outputUpdatedData[x].value[y].value;
            }
          })
        }
        else if (Object.keys(_this.outputUpdatedData[x]).length) {
          Object.keys(_this.outputUpdatedData[x]).forEach((y) => {
            if (
              _this.outputUpdatedData[x][y].value &&
              _this.outputsFromSer &&
              _this.outputsFromSer[x] &&
              _this.outputsFromSer[x][y] &&
              _this.outputsFromSer[x][y].value
            ) {
              _this.outputsFromSer[x][y].value =
                _this.outputUpdatedData[x][y].value;
            }
          });
        }
      });

      if (this.fromFor) {
        this.$emit("on-save", data);

        return;
      }
      let outputResult = "";
      if (data && data.oldValue) {
        outputResult +=
          `Old version:\n\n${data.oldValue}` +
          (data.newValue ? `\n\nNew version:\n\n${data.newValue}` : "");
      }
      axios
        .post(`/templatescalllog/updateSessionOutputs`, {
          id: this.sessionId,
          dynamicOutputId: this.dynamicOutputId,
          sessionData: { outputs: _this.outputsFromSer },
        })
        .then((response) => {
          if(manualEditing){
            axios.post(`/reports`, {
              status: "MODIFIED_OUTPUT",
              template: this.templateId,
              session: this.sessionId,
              outputId: data.nodeId,
              outputResult: outputResult,
            });
          }

        })
        .catch((error) => {
          reject(error);
        });
    },
    getReportCountByTemplate() {
      axios
        .get(`reports/getReportCountByTemplate`, {
          params: { template: this.templateId },
        })
        .then((res) => {
          this.reportCountByTemplate = res.data;
        });
    },

    getRegenerateNodes() {
      let res = {};
      this.outputStructure.forEach((x) => {
        res[x.nodeId] = false;
        if(x.items){
          x.items.forEach(y=>res[`${x.nodeId}-${y.name}`] = false)
        }
      });
      return res;
    },
    guidanceRegenerate(output, itemName, regenerateResult){
        let _this = this;
        if(!output.items){
           _this.outputUpdatedData[output.name].value = null;
          setTimeout(() => {
            _this.outputUpdatedData[output.name].value = regenerateResult;
            _this.saveNewOutput({}, output.nodeId)
          }, 0);
        }else if(itemName){

          if(_this.outputUpdatedData[output.name][itemName]){
            _this.outputUpdatedData[output.name][itemName].value = null;
            setTimeout(() => {
              _this.outputUpdatedData[output.name][itemName].value = regenerateResult;
              _this.saveNewOutput({}, output.nodeId)
            }, 0);
          }

        }
    },
    regenerate(output) {
      if (this.regenerateNodes[output.nodeId]) {
        return;
      }
      this.regenerateNodes[output.nodeId] = true;

      let _this = this;
      const resultsData = this.inputsResultsData;
      let data = {};
      Object.keys(resultsData).forEach((key) => {
        data[key] = resultsData[key] || "";
      });
      const dataToSend = {
        templateId: this.templateId,
        context: data,
        useSocket: false,
        targetOutputs: [output.nodeId],
      };
      if (this.taskId) {
        dataToSend.taskId = this.taskId;
      }
      if (this.templateCallId) {
        dataToSend.templateCallId = this.templateCallId;
      }

      axios
        .post("/templates/run", dataToSend)
        .then((response) => {
          Object.keys(response.data).forEach((x) => {
            const serData = {};
            serData[x] = response.data[x];
            _this.setData(serData);
          });

          //update credits
          this.regenerateNodes[output.nodeId] = false;
          this.$store.dispatch("appConfig/updateUserQuota");
        })
        .catch((error) => {
          this.regenerateNodes[output.nodeId] = false;
        });
    },
    deepFind(obj, path) {
      var paths = path.split('.')
        , current = obj
        , i;

      for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] == undefined) {
          return undefined;
        } else {
          current = current[paths[i]];
        }
      }
      return current;
    },

    setData(data) {
      let _this = this;
      if (data["_actions"] && data["_actions"].length > 0) {
        actionHandler(data["_actions"]);
      } else {
        const key = Object.keys(data)[0];
        if(!_this.outputUpdatedData[key]){
          return;
        }
        if (data[key].value != undefined) {
          if (data[key].value["_outputstructure"]) {
            if (!_this.outputUpdatedData[key].value) {
              _this.outputUpdatedData[key].value = [];
            }
            _this.outputUpdatedData[key].value.push(data[key].value);
          } 
          else if(data[key]["_type"] == "subtemplateOutput"){
            
            Object.keys(data[key].value).forEach(t => this.outputUpdatedData[key].value[t] = data[key].value[t])
            this.subTemplateKey = uuidv4()
            // const temp = JSON.parse(JSON.stringify(_this.outputUpdatedData[key].value))
            // _this.outputUpdatedData[key].value = null;
            // setTimeout(()=> {
            //    _this.outputUpdatedData[key].value = temp
            // }, 0)
            
          }
          else {
            _this.outputUpdatedData[key].value = null;
            setTimeout(() => {
               _this.outputUpdatedData[key].value = data[key].value;
            }, 0);
           
          }

          _this.outputUpdatedData[key].loading = false;
        }
        else if(data["_type"] == "customOutputPath" && data["_path"]){

          let curr = this.deepFind(data, data["_path"]).value
          let current = _this.outputUpdatedData;
          const vars = data["_path"].split(".")
          for(let i=0; i<vars.length; i++){
              if(current[vars[i]]){
              
                current = current[vars[i]];
                if(current){
                  current = current.value
                }
              }
          }
          Object.keys(curr).forEach(x => {
            if(current[x]){
              current[x] = curr[x]
            }
          })
          this.pathTemplateKey = uuidv4()

        }
        else {
          Object.keys(data[key]).forEach((x) => {
            _this.outputUpdatedData[key][x].value = null;;
            setTimeout(() => {
               _this.outputUpdatedData[key][x].value = data[key][x].value;
               _this.outputUpdatedData[key][x].loading = false;
            }, 0);
            
            
          });
        }
      }
    },
    initSocketEvents() {
      let _this = this;
      if (SocketioService.getSocket()) {
        SocketioService.getSocket().on("frontendframeworkoutput", (data) => {
          this.$store.dispatch("appConfig/updateUserQuota");
          
          const socketData = JSON.parse(data);
          _this.setData(socketData);
        });

        SocketioService.getSocket().on(
          "frontendframeworkoutputerror",
          (data) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "XIcon",
                variant: "danger",
                text: `${data}`,
              },
            });
          }
        );
      }
    },
    getOutputUpdatedData() {
      let value = {};
      const outputlength = this.outputStructure.length;
      for (let i = 0; i < outputlength; i++) {
        if (this.outputStructure[i].type == outputTypes.SPLITTER) {
          continue;
        }

        if (this.outputStructure[i].type == outputTypes.MULTIFIELD) {
          let convertedData = {};

          for (const element of this.outputStructure[i].items) {
            if (element.type == outputTypes.SPLITTER) {
              continue;
            }
            if (!this.outputsFromSer[this.outputStructure[i].name]) {
              continue;
            }
            convertedData[element.name] = {};
            convertedData[element.name].value =
              this.outputsFromSer[this.outputStructure[i].name][
                element.name
              ] ? this.outputsFromSer[this.outputStructure[i].name][
                element.name
              ].value: "";
            convertedData[element.name].loading = this.outputsFromSer[this.outputStructure[i].name][element.name]?
              (this.outputsFromSer[this.outputStructure[i].name][element.name]
                .loading || false): false;
          }
          value[this.outputStructure[i].name] = convertedData;
        } else if (this.outputStructure[i].type && this.outputsFromSer[this.outputStructure[i].name]) {
          value[this.outputStructure[i].name] = {
            value:
              "value" in this.outputsFromSer[this.outputStructure[i].name]
                ? this.outputsFromSer[this.outputStructure[i].name].value
                : this.outputsFromSer[this.outputStructure[i].name],
            loading:
              this.outputsFromSer[this.outputStructure[i].name].loading ||
              false,
          };
        }

        if(this.outputsFromSer[this.outputStructure[i].name]){
          if ("_label" in this.outputStructure[i]) {
            value[this.outputStructure[i].name]["_label"] =
              this.outputsFromSer[this.outputStructure[i].name] &&
              this.outputsFromSer[this.outputStructure[i].name]["_label"]
                ? this.outputsFromSer[this.outputStructure[i].name]["_label"]
                : this.outputStructure[i]["_label"];
          } else if (
            "label" in this.outputStructure[i] &&
            this.outputStructure[i]["label"] &&
            this.outputStructure[i].name
          ) {
            value[this.outputStructure[i].name]["label"] =
              this.outputsFromSer[this.outputStructure[i].name] &&
              this.outputsFromSer[this.outputStructure[i].name]["label"]
                ? this.outputsFromSer[this.outputStructure[i].name]["label"]
                : this.outputStructure[i]["label"];
          }
        }

      }
      return value;
    },

  },
};
</script>

<style>
.contilt-session {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  z-index: 1;
}

.contilt-output-for .regenerate {
  display: none;
}
</style>