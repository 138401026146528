<template>
  <b-form-group :label="input.label">

      <b-form-file
        @change="onFileUploads"
        :state="error ? false: true"
        accept=".json"
      />
      <b-form-invalid-feedback v-if="error">
        {{error}}
      </b-form-invalid-feedback>
  </b-form-group>
</template>
<script>
import { BFormFile, BFormGroup, BFormInvalidFeedback } from "bootstrap-vue";
import { readFile } from "@core/file/jsonFile";

export default {
  components: {
    BFormFile,
    BFormGroup,
    BFormInvalidFeedback
  },
  data(){
    return {
      error: null,
    }
  },
  props: ["input"],

  methods: {
    async onFileUploads(event){
        let res = "";
        this.error = null;
        if(event.target.files && event.target.files.length > 0){
            
            try{
                res = await readFile(event.target.files[0])
            }catch(e){
              this.error = e.message;
            }

            
            
        }
        this.$emit("file:change", res);
        console.log(event)
    }
  }
};
</script>
