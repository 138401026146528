<template>
  <div class="multifield">
    <b-card>
      <contilt-title :value="values['_label']" v-if="values['_label']">
      </contilt-title>
      <div  :key="index" v-for="(item, index) in items">
        <contilt-outputs-renderer
          :values="values[item.name] && values[item.name]"
          :forIndex="forIndex"
          :outputId="output.nodeId"
          @on-chat-generate="onGenerate"
          :generationLoading="generationLoading"
          @on-save="$emit('on-save', $event)"
          @show-history="$emit('show-history', {itemName: item.name})"
          :item="item"
        >
       
        </contilt-outputs-renderer>
         
      </div>

    </b-card>
   
  </div>
</template>

<script>
import { BCard, BButton, BSpinner, VBTooltip } from "bootstrap-vue";
import contiltTitle from "../maintitle/maintitle.vue";
import ContiltOutputsRenderer from "../putputsRenderer/outputsRenderer.vue";
export default {

  components: {
    BCard,
    BButton,
    BSpinner,
    ContiltOutputsRenderer,
    contiltTitle
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: ["values", "output", "forIndex", "generationLoading"],
  methods: {

    regenerate(name){

      this.$emit("on-regenerate", {itemName: name})
    },
    onGenerate(outputId, itemName, mres){
      this.$emit("on-chat-generate", this.output, itemName, mres)
    }
  },
  computed: {
    items() {
      return this.output.items;
    },
  },
};
</script>
<style scoped>
 .multifield-regenerate{
    position: absolute;
    left: -3px;
    z-index: 10;
 }
</style>