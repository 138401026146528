<template>
    <div class="chat-widget">
      <section class="chat-app-window">
        
     <b-form
        class="chat-app-form"
        @submit.prevent="sendMessage"
      >
     
     
        <b-input-group class=" form-send-message mr-1">
          <b-form-input
          :disabled="generating"
            v-model="chatInputMessage"
            placeholder="Enter your comment to rewrite the outcome."
          />
        </b-input-group>
        
        
          <b-button
            style="display:none;"
            variant="primary"
            type="submit"
            :disabled="generating"
          >
            Send
          </b-button>
        <b-spinner v-if="generating"
          class="mr-1"
          
        />
          <b-button
            v-if="!generating"
            variant="flat-success"
            v-b-tooltip.hover.v-primary
            title="History"
            @click="$emit('show-history')"
            class="btn-icon rounded-circle guidance-regenerate"
          >
            <feather-icon
              size="18"
              icon="ClockIcon"
            />
         
          </b-button>
      </b-form>

      </section>
      </div>
</template>

<script>
import {
  BForm, BFormInput, BInputGroup, BButton,BRow, BCol, BSpinner, VBTooltip
} from 'bootstrap-vue'
import axios from '@axios'
export default {
    components: {BForm, BFormInput, BInputGroup, BButton,BRow, BCol, BSpinner},
    props: ["outputId", "itemName", "forIndex", "dynamicOutputId", "value"],
  directives: {
    'b-tooltip': VBTooltip
  },
    created(){
      this.chat.push({
        message: this.value,
        time: new Date(),
        role: "assistant",
        senderId: 11,
        loading: false
      })
      axios.get("/outputs", {params: {outputId: this.outputId, session: this.$route.query.sessionId}}).then((d) => {
        if(d.data && d.data.results.length > 0){
          this.outputHistory = d.data.results[0].id;
          this.chat = d.data.results[0].data;
          
        }
      })
      
    },
    data(){
      return {
        generating: false,
        chatInputMessage: "",
        outputHistory: false,
        chat:[]
      }
    },
    methods: {
    async guidanceRegenerate(comment, itemName ){
      
      const res = await axios.post("/templates/guidanceGenerate", {targetOutput: this.outputId, forIndex: this.forIndex, outputItemName: itemName, templateCallId: this.$route.query.sessionId, dynamicOutputId: this.dynamicOutputId, comment, outputHistory: this.outputHistory});
      return res.data;
     
    },
    async sendMessage(){
            let _this = this;
      _this.generating = true;
      let qMessage = {
            message: this.chatInputMessage,
            time: new Date(),
            role: "user",
            senderId: 1,
            loading: false
          }
      
      _this.chat.push(qMessage)



      const comment = this.chatInputMessage;
      _this.chatInputMessage = "";
      try {
        const mres= await _this.guidanceRegenerate( comment, _this.itemName);
        let aMessage = {
            message: mres,
            time: new Date(),
            role: "assistant",
            senderId: 11,
            loading: false
          }
        _this.chat.push(aMessage)
        if(_this.outputHistory){
            await axios.patch(`/outputs/${_this.outputHistory}`, {data: _this.chat, outputId: _this.outputId, session: _this.$route.query.sessionId})
        }else {
          const history = await axios.post(`/outputs`, {data: _this.chat, outputId: _this.outputId, session: _this.$route.query.sessionId})
          _this.outputHistory = history.data;
        }
        _this.$emit("on-chat-generate", _this.outputId, _this.itemName, mres);

        _this.generating = false;  
      }catch(e){
  
      }
      this.generating = false;


      
    }
    }
}
</script>
<style scoped>
  .chat-widget {
    width: 100%;
  }
</style>
