<template>
  <b-form-group :label="input.label">
    <b-form-input
      v-model="inputValue"
      :placeholder="this.input.placeholder"
      
    >
    </b-form-input>
  </b-form-group>
</template>
<script>
import { BFormInput, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
  },
  props: ["input", "value"],
  computed: {
    inputValue:{
      set(newVal) {
        this.$emit("input:change", newVal);
      },
      get(){
        return this.value ? this.value : this.input.value,this.value
      }
    }
  }

};
</script>
