<template>
  <div class="main-templates" v-if="currentTemplate">
    <b-row>
      <b-col>
        <b-card>
          <b-card-title>{{ currentTemplate.name }}</b-card-title>
          <b-card-body class="inner-card-highlight">
            <b-card-text>
              Documentation:
            </b-card-text>
            <b-card-text>
              {{currentTemplate.documentation.description}}
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <b-card>
          <b-card-title>Inputs</b-card-title>
          <b-card-body style="margin-top: 10px;" :key="input.name" v-for="input in sampleinputs" class="inner-card-highlight">
            <b-card-text style="font-weight: bold;">
              {{input.name}}
            </b-card-text>
            <b-card-text>
              ({{input.label}}), {{input.documentation}}
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card>
          <b-card-title>Outputs</b-card-title>
          <b-card-body style="margin-top: 10px;" :key="output.name" v-for="output in outputStructure" class="inner-card-highlight">
            <b-card-text style="font-weight: bold;">
              {{output.name}}
            </b-card-text>
            <b-card-text v-if="output.items">
              <b-card-text style="padding-left: 15px;" :key="item.name" v-for="item in output.items">
                <span>- {{item.name}}</span><span v-if="item.documentation">, {{item.documentation}}</span>
              </b-card-text>
            </b-card-text>

            <b-card-text>
              ({{output.label || output._label}}), {{output.documentation}}
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col :lg="6">
        <contilt-inputs
          :title="currentTemplate.title"
          :generateLabel="currentTemplate.generateLabel"
          :generationLoading="generationLoading"
          :entityReaders="entityReaders"
          :userQuota="userQuota"
          ref="contilt-inputs"
          :sampleinputs="sampleinputs"
        ></contilt-inputs>
      </b-col>

      <b-col lg="6">
        <b-card>
          <ApiCodeComponent :template="currentTemplate" :inputs="inputsResultsData" />
        </b-card>
        

   
      </b-col>

    </b-row>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="generate"
          block
          :disabled="generationLoading"
        >
          <b-spinner v-if="generationLoading" small />
          Generate example output
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="pageOutputData">
      <b-col>
        <b-card>
          <prism
          
            language="javascript"
            class="rounded code-to-copy"
          >
            {{ pageOutputData }}
          </prism>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "@axios";
import ContiltOutput from "./outputsfactory/outputsfactory.vue";
import contiltInputs from "./inputsFactory/inputsFactory.vue";
import AddReview from "../../../contilt/addReview/addreview.vue";
import SocketioService from "./socketio.service";
import { v4 as uuidv4 } from "uuid";
import { BRow, BCol, BImg, BOverlay, BButton, BCard,BCardTitle, BCardText, BCardBody, BSpinner } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { onBeforeUnmount } from "@vue/composition-api";
import router from '@/router';
import { ExportToCsv } from 'export-to-csv';
import Ripple from "vue-ripple-directive";
import ApiCodeComponent from "@core/components/global/ApiCodeComponent.vue"
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
export default {
  components: {
    BRow,
    BCol,
    BImg,
    BOverlay,
    ContiltOutput,
    contiltInputs,
    AddReview,
    BButton,
    ApiCodeComponent,
    Prism,
     BCard,BCardTitle, BCardText, BCardBody, BSpinner
  },
    directives: {
    Ripple,
  },
  setup() {
    onBeforeUnmount(() => {
      SocketioService.disconnect();
    });
    const { userQuota } = useAppConfig();
    return { userQuota };
  },
  methods: {

    generate() {
      this.generateUUid = uuidv4();
      this.generationLoading = true;
      const resultsData = this.inputsResultsData
      let data = {};
      Object.keys(resultsData).forEach((key) => {
        data[key] = resultsData[key] || "";
      });
      const dataToSend = {
        templateId: this.currentTemplate.id,
        context: data,
      };
      this.pageOutputData = null;
      let self = this;
      setTimeout(() => {


        SocketioService.setupSocketConnection();
        let promise = new Promise((reslove)=> reslove())



          axios
          .post("/templates/run", dataToSend)
          .then((response) => {
       
            this.pageOutputData = response.data;
            this.generationLoading = false;
            
         

            
            //update credits
            
            this.$store.dispatch("appConfig/updateUserQuota");
          })
          .catch((error) => {
            this.generationLoading = false;
 
          });
  
        
      }, 0);
    },
    getTemplatePromise() {
      const promise = new Promise((resolve, reject) => {
        return axios
          .get(`/templates/${this.$route.params.id}`)
          .then((response) => {
            if (response.data) {
              resolve(response);
            }
          })
          .catch((error) => {
            this.generationLoading = false;
            reject(error);
          });
      });
      return promise;
    },
    getSessionDataPromise() {
      const promise = new Promise((resolve, reject) => {
        return axios
          .get(`/templatescalllog/${this.$route.query.sessionId}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return promise;
    },
  },
  watch: {
    currentTemplate(old, newVal){
      setTimeout(() => {
        this.inputsResultsData = this.$refs["contilt-inputs"].resultsData
      }, 100);
      
    }
  },
  data() {
    return {
      inputsResultsData: null,
      taskCallLogId: null,
      templateCallLogId: null,
      leftSide: 6,
      rightSide: 6,
      downImg: require("@/assets/images/pages/coming-soon.svg"),
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      generationLoading: false,
      information: null,
      pageOutputData: null,
      currentTemplate: null,
      code: "{a: '1', b: '2'}",
      generateUUid: uuidv4(),
    };
  },
  computed: {
    outputStructure() {
      const outputs = this.currentTemplate.outputs;
      for(let i=0; i<outputs.length; i++){
        if(!"documentation" in outputs[i]){
          outputs[i].documentation =  this.currentTemplate.documentation.outputs[i].description
        }
      }
      return outputs
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/coming-soon-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },

    entityReaders() {
      return this.$store.state.templatesStore.selectedTemplate.entityReaders;
    },

    sampleinputs() {
      const inputs = this.$store.state.templatesStore.selectedTemplate.inputs;
      for(let i=0; i<inputs.length; i++){
        inputs[i].documentation = this.$store.state.templatesStore.selectedTemplate.documentation.inputs[i].description
      }
      return inputs
    },

  },
  beforeUnmount() {
    SocketioService.disconnect();
  },
  // mounted() {
  //   this.$watch(
  //       () => {
  //           return this.$refs["contilt-inputs"].resultsData
  //       },
  //     (val) => {
  //       this.inputsResultsData = val;
  //     }
  //   )
  // },
  created() {
    let _this =this;
    this.currentTemplate = this.$store.state.templatesStore.selectedTemplate;
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    if (this.currentTemplate && this.currentTemplate.id) {
      const information = {};
      this.currentTemplate.inputs &&
        this.currentTemplate.inputs.forEach((element) => {
          information[element.name] = element;
        });
      this.information = information;
    } else {
      const selectedTemplatePromise = this.getTemplatePromise();
      let promisesArray = [selectedTemplatePromise];

      if (this.$route.query.sessionId) {
        const selectedSessionPromise = this.getSessionDataPromise();

        promisesArray.push(selectedSessionPromise);
      }

      Promise.all(promisesArray).then((values) => {
        const selected = values[0].data;

        if (values[0]) {
          // selected template data here
          this.currentTemplate = values[0].data;
          this.$store.commit("templatesStore/setSelectedTemplate", selected);
          this.currentTemplate = selected;
        }

        if (values[1]) {
          //session data here
          // this.pageOutputData = this.$route.params.sessionData.outputs;
          let sessionData = values[1].data.sessionData;
          _this.templateCallLogId = values[1].data.id;
          _this.taskCallLogId = values[1].data.task;
          if (sessionData && sessionData.inputs && sessionData.outputs) {
            const inputsUpdatedValues = [];
            let helpInput = {};
            selected.inputs.map((input) => {
              helpInput = { ...input };
              helpInput.value = sessionData.inputs[input.name] || input.value;
              inputsUpdatedValues.push(helpInput);
            });
            selected.inputs = [...inputsUpdatedValues];
          }
          _this.pageOutputData = sessionData.outputs;
        }
      });
    }
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.commit("templatesStore/setSelectedTemplate", {});
  },
};
</script>
<style>
 .download-csv {
      position: absolute;
    top: -48px;
    right: 59px;
 }
 .inner-card-highlight{
      background: #f7f7f7;
    border-radius: 5px;
 }
</style>