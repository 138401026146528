<template>
    <div :class="{'sub-template-output': dependedOutput['0'] && dependedOutput['0'].type == 'subTemplate' }">
    
        <b-card-title>{{ dependedOutput.label }}</b-card-title>
        <loading-data v-if="!outputStructure || !values.value || !Object.keys(values.value).length" />
        <ContiltOutput
    
         
            :taskId="taskId"
            :templateCallId="templateCallId"
            :generationLoading="generationLoading"
            :dynamicOutputData="dynamicOutputData"
            :outputStructure="outputStructure"
            :outputsFromSer="values.value"

          />
        <div :key="index" v-for="(x, index) in subtemplates">
             
            <!-- <SubtemplateOutput :generationLoading="generationLoading" :dynamicOutputData="dynamicOutputData" :templateCallId="templateCallId" :taskId="taskId" :subtemplateOutput="x.structure" :values="values.value[x.id]" /> -->

          
        </div>

    
    </div>
</template>

<script>
import loadingData from '@/contilt/outputelements/loading/loading-data.vue';

import {
  BCard,
  BCardTitle,

} from "bootstrap-vue";

import { v4 as uuidv4 } from "uuid";
export default {
    props: ["dependedOutput", "triggerKey", "values", "taskId", "templateCallId", "dynamicOutputData", "generationLoading"],
    data(){
        return {
            template: null,
            subtemplates: null
        }
    },
    created(){

         
    },
    computed: {
        outputStructure(){
            let _this = this;
            if(!_this.dependedOutput.structure[_this.dependedOutput.name]){
                return;
            }
            const outputStructure = [];
            Object.keys(_this.dependedOutput.structure[_this.dependedOutput.name]).forEach(x => {
                const s = _this.dependedOutput.structure[_this.dependedOutput.name][x];
                if(!s.structure){
                    Object.keys(s).forEach(t => {
                        if(typeof s[t] === 'object' && Object.keys(s[t]).length){
                            // let arr = [];
                            // Object.keys(s[t]).forEach(tt => {
                            //     s[t][tt].type =  "agentTemplate"
                            //     s[t][tt].name = t;
                            //     arr.push(s[t][tt]);
                            // })
                            // s.name = t;
                            // s.id = t;
                            if(!s.structure){
                                s.type = "agentTemplate"
                                const obj = {};
                                obj[x] = {};
                                obj[x][t] = s[t];
                                s.structure = obj
                            }else {
                                s.structure[x][t] = s[t]
                            }
                            
                        }   
                    })
                    if(s.outputs){
                        s.type = "subTemplate"
                    }else {
                        s.id = x;
                        s.name = x;
                    }
                   
                }else {
                    s.id = x;
                    s.name = x;
                    s.type = s.type || "subTemplate";
                    s.subTemplateKey = uuidv4();
                }

                outputStructure.push(s)
            })
            return outputStructure;
        }
    },
    components: {BCard, loadingData, BCardTitle, ContiltOutput: () => import('./outputsfactory/outputsfactory.vue')},
    // watch: {
    //     triggerKey(){
    //         this.subtemplates.forEach(x=> {
    //             x.subTemplateKey = uuidv4();
    //         })
    //     },
    // },
    // computed: {
    //     subtemplates(){
    //         let res = [];
    //         Object.keys(this.dependedOutput.structure[this.dependedOutput.name]).forEach(x => {
    //             const s = this.dependedOutput.structure[this.dependedOutput.name][x];
    //             s.id = x;
    //             s.subTemplateKey = uuidv4();
    //             res.push(s)
    //         })

    //         return res;
    //     }
    // },
    methods: {

    }
}
</script>
<style>
    .sub-template-output{
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 15px;
        background-color: rgba(0, 0, 0, 0.03);
    }
    .main-output .card-title {
        font-size: 16px !important;
        
    }

</style>
<style scoped>
    .main-output .card-title {
        color: #a5a5a5;
        
    }
</style>