<template>

  <div>
        <v-select
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :reduce="option => option.value"
          :options="options"
        />
      <b-button
        @click="copy"
      style="margin-top: 10px;"
        variant="dark"
      >
        <feather-icon
          icon="CopyIcon"
          class="mr-50"
        />
        <span class="align-middle">Copy</span>
      </b-button>
        <prism
         
          language="javascript"
          class="rounded code-to-copy"
        >
          {{ code }}
        </prism>

  </div>


</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { BButton } from 'bootstrap-vue';
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import axios from '@axios';
export default {
    props: ["template", "inputs"],
  components: {
    BButton,
    Prism,
    vSelect
  },
  created(){

    if(!this.token){
      axios.get(`tokens/getAPItoken`).then(res => {
        this.token = res.data;
      })
    }


  },
  data() {
    return{
      template: null,
      inputs: null,
      options: [{title: "CURL", value: "curl"},{title: "Fetch", value: "fetch"}],
      selected: "curl",
      token: null
      }
  },
  methods: {

    async copy(){
      try {
        const code = document.querySelector(".code-to-copy code").textContent
        await navigator.clipboard.writeText(code);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            icon: 'CopyIcon',
            variant: 'success',
            text: `Code Copied!`,
          },
        })
        /* Resolved - text copied to clipboard successfully */
      } catch (err) {
        console.error('Failed to copy: ', err);
        /* Rejected - text failed to copy to the clipboard */
      }
    }
  },
  computed: {
    code() {
      if(!this.template){
        return ""
      }
      const baseUrl = process.env.VUE_APP_BASE_URL + "templates/run"
      const data = {
        templateId: this.template.id,
        context: {}
      };
      
      this.template.inputs.forEach(x => {
        if(x.name){
          data.context[x.name] = this.inputs && this.inputs[x.name] ? this.inputs[x.name]: ""
        }
        
      })
      if(this.selected == "curl"){
        const curl = 
        `curl '${baseUrl}' \\
            -H 'Authorization: Bearer ${this.token}' \\
            -H 'Content-Type: application/json;charset=UTF-8' \\
            --data-raw '${JSON.stringify(data)}' \\
            --compressed`
        return curl;
      }
      else {
        return `fetch("${baseUrl}", {
      "headers": {
        "Authorization": "Bearer ${this.token}",
        "content-type": "application/json;charset=UTF-8"
      },
      "body": '${JSON.stringify(data)}',
      "method": "POST"
    });`
      }

    }
  }
 
}
</script>
<style>

  pre {
    background: #4b4b4b !important
  }
</style>