


export const calculateSize = function (img, maxWidth, maxHeight) {
    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }
    return [width, height];
  
};

export const compressImageToBase64 = (file, maxWidth, maxHeight, quality, force, type) => {
  return new Promise((resolve, reject) => {
    compressImage(file,maxWidth, maxHeight, quality, force, type).then(data => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }).catch(err => {
      reject(err)
    })
  });
}

export const compressImage = (file, MAX_WIDTH = 150, MAX_HEIGHT=100, QUALITY=0.7, force=false, type="image/jpeg") => {

  return new Promise((resolve, reject) => {
    const MIME_TYPE = type;
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onerror = function (err) {
      reject(err)
    };
    img.onload = function () {
      URL.revokeObjectURL(this.src);
      const [newWidth, newHeight] = force ? [MAX_WIDTH, MAX_HEIGHT ] : calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        (blob) => {
          resolve(blob)
        },
        MIME_TYPE,
        QUALITY
      );
    };
  })

};
